.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.about-subtitle {
	width: 80% !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 20px !important;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		display: none;
	}

	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}

/* about.css */

/* ... (other styles remain unchanged) */

.technologies-container {
    margin-top: 40px;
}

.technologies-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.technologies-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 30px;
}

.tech-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.tech-item:hover {
    transform: scale(1.05);
}

.tech-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    filter: grayscale(50%);
    transition: filter 0.3s ease-in-out;
}

.tech-item:hover .tech-icon {
    filter: grayscale(0%);
}

.tech-title {
    font-size: 18px;
    color: #555;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
